@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

@layer base {
  p,
  a,
  body,
  h1,
  h2,
  h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
  }

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 42px;
    font-weight: 300;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
  }

  body {
    background-color: black;
  }
}

@media screen and (max-width: 640px) {
  p {
    font-size: 16px;
  }

  h1 {
    font-size: 38px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }
}
